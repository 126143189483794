import {
    TOOL_UPDATE_CUSTOMER,
    TOOL_UPDATE_CUSTOMERS,
    TOOL_UPDATE_INTERNET_PRODUCT,
    TOOL_UPDATE_INTERNET_PRODUCTS,
    TOOL_UPDATE_LOGS,
    TOOL_UPDATE_ORDER,
    TOOL_UPDATE_ORDER_ID,
    TOOL_UPDATE_ORDERED_INTERNET_PRODUCT,
    TOOL_UPDATE_PRODUCTS,
    TOOL_UPDATE_STATUS,
    TOOL_UPDATE_MIGRATED_PHONE_NUMBERS,
    TOOL_UPDATE_MIGRATED_MOBILE_NUMBERS,
    TOOL_UPDATE_NEW_PHONE_NUMBER,
    TOOL_UPDATE_NEW_MOBILE_NUMBERS,
    TOOL_UPDATE_PRODUCT_EMAIL_SENT,
    TOOL_UPDATE_ORDER_PACKAGE,
    TOOL_UPDATE_PRODUCTS_TO_NOTIFY,
    TOOL_UPDATE_ORDERING_INTERNET_PRODUCT,
    TOOL_UPDATE_ORDERING_NEW_PHONE_PRODUCT,
    TOOL_UPDATE_ORDERING_PHONE_PRODUCT_MIGRATION,
    TOOL_UPDATE_ORDERING_NEW_MOBILE_PRODUCT, TOOL_UPDATE_ORDERING_MOBILE_PRODUCT_MIGRATION
} from './actionTypes';

const initialState = {
    orderId: null,
    customer: {},
    customers: [],
    order: {},
    logs: [],
    products: {},
    product: {},
    orderedInternetProduct: {},
    internetProducts: [],
    newPhoneNumber: {},
    migratedPhoneNumbers: [],
    newMobileNumbers: [],
    migratedMobileNumbers: [],
    status: {},
    loadedStatus: false,
    productEmailStatus: {},
    orderPackage: {},
    notifyProducts: [],
    orderingInternetProduct: false,
    orderingNewPhoneProduct: false,
    orderingPhoneProductMigration: false,
    orderingNewMobileProduct: false,
    orderingMobileProductMigration: false,
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case TOOL_UPDATE_CUSTOMER: {
            const {customer} = action.payload;

            return {...state, customer};
        }
        case TOOL_UPDATE_CUSTOMERS: {
            const {customers} = action.payload;

            return {...state, customers};
        }
        case TOOL_UPDATE_ORDER: {
            const {order} = action.payload;

            return {...state, order};
        }
        case TOOL_UPDATE_LOGS: {
            const {logs} = action.payload;

            return {...state, logs};
        }
        case TOOL_UPDATE_PRODUCTS: {
            const {products} = action.payload;

            return {...state, products};
        }
        case TOOL_UPDATE_INTERNET_PRODUCTS: {
            const {internetProducts} = action.payload;

            return {...state, internetProducts};
        }
        case TOOL_UPDATE_STATUS: {
            const {status} = action.payload;

            return {...state, status, loadedStatus: true};
        }
        case TOOL_UPDATE_ORDER_ID: {
            const {orderId} = action.payload;

            return {...state, orderId};
        }
        case TOOL_UPDATE_INTERNET_PRODUCT: {
            const {product} = action.payload;

            return {...state, product};
        }
        case TOOL_UPDATE_ORDERED_INTERNET_PRODUCT: {
            const {product} = action.payload;

            return {...state, orderedInternetProduct: product};
        }
        case TOOL_UPDATE_MIGRATED_PHONE_NUMBERS: {
            const {numbers} = action.payload;

            return {...state, migratedPhoneNumbers: numbers};
        }
        case TOOL_UPDATE_NEW_PHONE_NUMBER: {
            const {number} = action.payload;

            return {...state, newPhoneNumber: number};

        }
        case TOOL_UPDATE_MIGRATED_MOBILE_NUMBERS: {
            const {numbers} = action.payload;

            return {...state, migratedMobileNumbers: numbers};
        }
        case TOOL_UPDATE_NEW_MOBILE_NUMBERS: {
            const {numbers} = action.payload;

            return {...state, newMobileNumbers: numbers};
        }
        case TOOL_UPDATE_PRODUCT_EMAIL_SENT: {
            const {productEmailStatus} = action.payload;

            return {...state, productEmailStatus};
        }
        case TOOL_UPDATE_ORDER_PACKAGE: {
            const {orderPackage} = action.payload;

            return {...state, orderPackage};
        }
        case TOOL_UPDATE_PRODUCTS_TO_NOTIFY: {
            const {products} = action.payload;

            return {...state, notifyProducts: products};
        }
        case TOOL_UPDATE_ORDERING_INTERNET_PRODUCT: {
            const {ordering} = action.payload;

            return {...state, orderingInternetProduct: ordering};
        }
        case TOOL_UPDATE_ORDERING_NEW_PHONE_PRODUCT: {
            const {ordering} = action.payload;

            return {...state, orderingNewPhoneProduct: ordering};
        }
        case TOOL_UPDATE_ORDERING_PHONE_PRODUCT_MIGRATION: {
            const {ordering} = action.payload;

            return {...state, orderingPhoneProductMigration: ordering};
        }
        case TOOL_UPDATE_ORDERING_NEW_MOBILE_PRODUCT: {
            const {ordering} = action.payload;

            return {...state, orderingNewMobileProduct: ordering};
        }
        case TOOL_UPDATE_ORDERING_MOBILE_PRODUCT_MIGRATION: {
            const {ordering} = action.payload;

            return {...state, orderingMobileProductMigration: ordering};
        }
        default: {
            return {...state};
        }
    }
}

export default reducer;
