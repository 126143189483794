import {
    TOOL_UPDATE_FACILITY_XL_CUSTOMER,
    UPDATE_ORDER_PROCESS_CONTRACT_DURATION,
    UPDATE_ORDER_PROCESS_RELATED_PRODUCT_SETTINGS
} from './actionTypes';


const initialState = {
    contractDuration: 36,
    productSettings: [],
    facilityXLCustomer: {},
};

function orderProcess(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ORDER_PROCESS_CONTRACT_DURATION: {
            const {contractDuration} = action.payload;

            return {...state, contractDuration};
        }
        case UPDATE_ORDER_PROCESS_RELATED_PRODUCT_SETTINGS: {
            const {settings} = action.payload;

            return {...state, productSettings: settings};
        }
        case TOOL_UPDATE_FACILITY_XL_CUSTOMER: {
            const {facilityXLCustomer} = action.payload;

            return {...state, facilityXLCustomer};
        }
        default: {
            return {...state};
        }
    }
}

export default orderProcess;
