import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import orderToolReducer from '../admin/orderToRemoteTool/store/reducer';
import orderProcessReducer from './store/orderProcessReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form"
    order: orderToolReducer,
    orderProcess: orderProcessReducer,
});
const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk))
);

export default store;
