export default (function submitOrder(values) {
    fetch('/orders/submit', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
    }).then(response => response.json())
        .then(data => window.location = `/orders/${data.id}/sign`);
});
