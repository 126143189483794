import React from 'react';
import {getFormValues, reduxForm, submit} from 'redux-form';
import validate from '../validate';
import connect from "react-redux/es/connect/connect";

class InstallationConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orderRequestInProgress: false,
        };
    }

    render() {
        const {handleSubmit, previousPage, formData, generatePdf, dispatch} = this.props;
        const {orderRequestInProgress} = this.state;
        return (
            <form onSubmit={handleSubmit}>
                <hr/>
                <div className="row infoRow">
                    <div className="col-12 col-md-6">
                        <h4>Contact</h4>
                        <p>
                            {formData.contact}<br/>
                            {formData.company}<br/>
                            {formData.email}<br/>
                            {formData.phone}<br/>
                        </p>
                        <h4>Persoonlijke Informatie</h4>
                        <p>
                            {formData.initials} {formData.infix} {formData.lastname}<br/>
                            {formData.street} {formData.number} {formData.appendix}<br/>
                            {formData.zipcode}<br/>
                            {formData.city}<br/>
                        </p>

                    </div>
                    <div className="col-12 col-md-6">
                        <h4>Informatie</h4>
                        <div className="text-white-space-pre-line">
                            {formData.installationDescription}
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="previousStep" onClick={previousPage}>
                            &lt; Vorige stap
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-block btn-blue" onClick={() => generatePdf(formData)}>Werkbon</button>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-block btn-red" disabled={orderRequestInProgress} onClick={() => {
                            if (!orderRequestInProgress) {
                                this.setState({orderRequestInProgress: true});
                                dispatch(submit('wizard'));
                            }
                        }}>
                            {orderRequestInProgress && <i className="fas fa-circle-notch fa-spin mr-1"/>}
                            Ondertekenen
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

InstallationConfirmation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(InstallationConfirmation);

InstallationConfirmation = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(InstallationConfirmation);

export default InstallationConfirmation;
