const IBAN = require('iban');

const validate = values => {
    const errors = {};
    if (!values.telephoneType) {
        errors.telephoneType = 'Dit veld is verplicht';
    }
    if (values.telephoneType === 'existing') {
        const phoneNumbersErrors = [];
        values.phoneNumbers.forEach((phoneNumber) => {
            if (!phoneNumber || !phoneNumber.length) {
                phoneNumbersErrors.push(true);
            } else {
                phoneNumbersErrors.push(null);
            }
        });
        if (phoneNumbersErrors.length) {
            errors.phoneNumbers = phoneNumbersErrors
        }
        if (!values.providerName) {
            errors.providerName = 'Dit veld is verplicht';
        }
        if (!values.providerNumber) {
            errors.providerNumber = 'Dit veld is verplicht';
        }
    }

    // personal information
    if (!values.gender) {
        errors.gender = 'Dit veld is verplicht';
    }
    if (!values.initials) {
        errors.initials = 'Dit veld is verplicht';
    }
    if (!values.lastname) {
        errors.lastname = 'Dit veld is verplicht';
    }
    if (!values.company) {
        errors.company = 'Dit veld is verplicht';
    }
    if (!values.contact) {
        errors.contact = 'Dit veld is verplicht';
    }
    if (!values.phone) {
        errors.phone = 'Dit veld is verplicht';
    }
    if (!values.email) {
        errors.email = 'Dit veld is verplicht';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Dit is geen valide email';
    }
    if (!values.coc) {
        errors.coc = 'Dit veld is verplicht';
    }
    if (!values.projectContact) {
        errors.projectContact = 'Dit veld is verplicht';
    }
    if (!values.projectEmail) {
        errors.projectEmail = 'Dit veld is verplicht';
    }
    if (!values.projectPhone) {
        errors.projectPhone = 'Dit veld is verplicht';
    }

    // Installation address
    if (!values.street) {
        errors.street = 'Dit veld is verplicht';
    }
    if (!values.housenr) {
        errors.housenr = 'Dit veld is verplicht';
    }
    if (!values.zipcode) {
        errors.zipcode = 'Dit veld is verplicht';
    }
    if (!values.city) {
        errors.city = 'Dit veld is verplicht';
    }

    // Invoice information
    if (values.differentInvoiceAddress) {
        if (!values.invoiceCompany) {
            errors.invoiceCompany = 'Dit veld is verplicht';
        }
        if (!values.invoiceContact) {
            errors.invoiceContact = 'Dit veld is verplicht';
        }
        if (!values.invoiceEmail) {
            errors.invoiceEmail = 'Dit veld is verplicht';
        }
        if (!values.invoiceStreet) {
            errors.invoiceStreet = 'Dit veld is verplicht';
        }
        if (!values.invoiceHousenr) {
            errors.invoiceHousenr = 'Dit veld is verplicht';
        }
        if (!values.invoiceZipcode) {
            errors.invoiceZipcode = 'Dit veld is verplicht';
        }
        if (!values.invoiceCity) {
            errors.invoiceCity = 'Dit veld is verplicht';
        }
    }

    // misc order
    if (!values.miscDescription) {
        errors.miscDescription = 'Dit veld is verplicht';
    }

    // Contract
    if (!values.agreedPrivacy) {
        errors.agreedPrivacy = 'Dit veld is verplicht';
    }

    if (!values.agreedInstallation) {
        errors.agreedInstallation = 'Dit veld is verplicht';
    }

    if (!values.agreedContractTerm) {
        errors.agreedContractTerm = 'Dit veld is verplicht';
    }

    if (values.discount && !values.membershipNumber) {
        errors.membershipNumber = 'Dit veld is verplicht';
    }

    // agreement
    if (!values.agreedInstallation) {
        errors.agreedInstallation = 'Dit veld is verplicht';
    }

    return errors;
};

export default validate;
