import React from 'react';
import {
    STEP_ALARM,
    STEP_CONFIRMATION,
    STEP_INFORMATION, STEP_INSTALLATION, STEP_MISC,
    STEP_MOBILE,
    STEP_OPTIONS,
    STEP_TELEPHONE
} from './const/steps';

const Steps = props => {
    const {backToPage, currentPage, steps} = props;

    const titles = {};

    titles[STEP_OPTIONS] = 'Options';
    titles[STEP_MOBILE] = 'Mobiele Telefonie';
    titles[STEP_TELEPHONE] = 'Telefoon';
    titles[STEP_ALARM] = 'Alarm';
    titles[STEP_MISC] = 'Vrije Invoer';
    titles[STEP_INFORMATION] = 'Uw Gegevens';
    titles[STEP_CONFIRMATION] = 'Bevestiging';
    titles[STEP_INSTALLATION] = 'Installatie';

    return (
        <div className="row stepbuttonContainer">
            {steps.map((step, index) => {
                return <div key={`step-header-${index}`} onClick={() => backToPage(step)} className={'col-md' + (currentPage === step ? ' active' : '') + (steps.indexOf(step) < steps.indexOf(currentPage) ? ' completed pointer' : '')}>
                    <span className="step">{index + 1}</span>{titles[step]}
                </div>;
            })}
        </div>
    );
};

export default Steps;
