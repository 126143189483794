import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.min.css';

class InstallationDatePicker extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        onChange: PropTypes.func.isRequired,
        inline: PropTypes.bool,
    };

    render() {
        const startDate = new Date();
        const {value, onChange, inline = true} = this.props;

        return <DatePicker
            className="form__input"
            onChange={onChange}
            selected={value}
            startDate={value}
            selectsRange={false}
            inline={inline}
        />
    }
}

export default InstallationDatePicker;
