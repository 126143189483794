import React from 'react';
import PropTypes from 'prop-types';
import {
    fetchInstallationData,
    generateInstallationPdfRequest,
    getNextPage,
    getPreviousPage
} from '../helpers';
import RemoteSubmitButton from '../RemoteSubmitButton';
import InstallationForm from './InstallationForm';
import Steps from '../Steps';
import InstallationPriceList from './InstallationPriceList';
import {isEmptyObject} from '../../helper/object';
import InstallationConfirmation from './InstallationConfirmation';
import InstallationContactInformation from './InstallationContactInformation';
import {STEP_CONFIRMATION, STEP_INFORMATION, STEP_INSTALLATION} from '../const/steps';

class InstallationFormWizard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: STEP_INSTALLATION,
            products: [],
            installation: {},
            steps: [],
        };
    }

    componentDidMount() {
        const {installation} = this.props;

        fetchInstallationData(installation).then(data => {
            this.setState({installation: data, steps: this.getSteps()});
        });
    }

    nextPage = () => {
        const {page, steps} = this.state;
        const nextPage = getNextPage(page, steps);

        this.setState({page: nextPage});
    }

    previousPage = () => {
        const {page, steps} = this.state;
        const nextPage = getPreviousPage(page, steps);

        this.setState({page: nextPage});
    }

    backToPage = newPage => {
        const {page, steps} = this.state;
        if (steps.indexOf(page) > steps.indexOf(newPage)) {
            this.setState({page: newPage});
        }
    }

    generatePdf = formData => {
        const {installation} = this.state;

        generateInstallationPdfRequest(installation.id, formData);
    }

    getSteps = () => [STEP_INSTALLATION, STEP_INFORMATION, STEP_CONFIRMATION];

    render() {
        const {onSubmit} = this.props;
        const {page, installation, steps} = this.state;

        if (isEmptyObject(installation)) {
            return null;
        }

        return (
            <div className="container-fluid orderForm">
                <div className="row">
                    <div className="col-lg-8 mb-3">
                        <Steps backToPage={this.backToPage} currentPage={page} steps={steps} />
                        {page === STEP_INSTALLATION && <InstallationForm onSubmit={this.nextPage} installation={installation} />}
                        {page === STEP_INFORMATION && <InstallationContactInformation previousPage={this.previousPage} onSubmit={this.nextPage}/>}
                        {page === STEP_CONFIRMATION && <InstallationConfirmation previousPage={this.previousPage} onSubmit={onSubmit} generatePdf={this.generatePdf} />}
                    </div>
                    <div className="col-lg-4">
                        <div className="listTop">
                            <h5>Kosten Installatie</h5>
                        </div>
                        <div className="itemlistContainer">
                            <InstallationPriceList />
                            <div className="verticalSpacerMedium" />
                            <RemoteSubmitButton currentPage={page} steps={steps} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

InstallationFormWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default InstallationFormWizard;
