import React from 'react';
import {change} from 'redux-form';

const renderField = ({input, label, type, meta: {touched, error}, id}) => {
    if (type === 'textarea') {
        return <textarea id={id} className={'form-control' + (touched && error ? ' invalid' : '')} {...input} placeholder={label} />
    }

    return <div className="form__input-wrapper">
        <input id={id} className={'form__input' + (touched && error ? ' invalid' : '')} {...input} placeholder={label} type={type} min={0}/>
    </div>;
};

export const renderProductField = ({input, label, type, meta: {touched, error}, id}) => {
    return <input id={id} className={'specifications__number-input' + (touched && error ? ' invalid' : '')} {...input} placeholder={label} type={type} min={0}/>

};

export const renderNumberField = ({input, label, type, meta: {touched, error, dispatch, form}, id}) => {
    const {name} = input;

    return <div className="form__input-wrapper number-wrapper">
        <input id={id} className={'form__input' + (touched && error ? ' invalid' : '')} {...input} placeholder={label} type={type} min={0} />
        <div className="add-number-btn flex center pointer" onClick={() => {
            document.getElementById(id).stepUp();

            dispatch(change(form, name, document.getElementById(id).value));
        }}>
            <i className="fas fa-plus" />
        </div>
    </div>
}

export default renderField;
