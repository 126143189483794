import React from 'react';
import {connect} from 'react-redux';
import {submit} from 'redux-form';

const RemoteSubmitButton = ({dispatch, currentPage, steps}) => (
    <button className="specifications__price-btn btn" type="button" onClick={() => {dispatch(submit('wizard'))}}>
        <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.455" height="16.733" viewBox="0 0 16.455 16.733">
                <g id="Group_97" data-name="Group 97" transform="translate(0.5 0.811)">
                    <path id="Path_1298" data-name="Path 1298"
                          d="M1547.335,2309a1.558,1.558,0,0,0-.136.107c-1.948,1.948-5.35,5.46-7.3,7.409a1.476,1.476,0,0,0-.109.132l-1.055-1.074,5.67-5.782-.024-.048h-5.423l-7.081.048v-1.542l12.516-.048-5.727-5.61,1.115-1.113a.561.561,0,0,0,.08.134c1.95,1.954,5.382,5.269,7.335,7.22a1.671,1.671,0,0,0,.135.108Z"
                          transform="translate(-1531.881 -2301.477)" strokeWidth="1" />
                </g>
            </svg>
            {steps.indexOf(currentPage) < steps.length - 1 && <span>Volgende stap</span>}
            {steps.indexOf(currentPage) === steps.length - 1 && <span>Ondertekenen</span>}
        </div>
    </button>
);

export default connect()(RemoteSubmitButton);
