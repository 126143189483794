import React from 'react';
import PropTypes from 'prop-types';
import TimeKeeper from 'react-timekeeper';

class InstallationTimePicker extends React.Component {
    static propTypes = {
        input: PropTypes.object.isRequired,
    };

    render() {
        const {value, onChange} = this.props.input;

        return <TimeKeeper
            time={value}
            hour24Mode={true}
            onChange={value => onChange({hour: value.hour, minute: value.minute})}
            switchToMinuteOnHourSelect={true}
            switchToMinuteOnHourDropdownSelect={true}
        />
    }
}

export default InstallationTimePicker;
