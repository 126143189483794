import React from 'react';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';

import connect from "react-redux/es/connect/connect";
import renderField from '../../components/blocks/renderField';
import renderError from '../../components/blocks/renderError';
import validate from '../validate';

let InstallationContactInformation = (props, state) => {
    const {handleSubmit, previousPage, formData} = props;

    return (
        <form onSubmit={handleSubmit}>
            <hr/>
            <h4>Persoonlijke Informatie</h4>
            <div className="row mb-3">
                <div className="col-2">
                    <div className="form-group">
                        <Field name="gender" component="select" className="form-control">
                            <option value="m">Dhr.</option>
                            <option value="f">Mw.</option>
                        </Field>
                    </div>
                </div>
                <div className="col">
                    <Field name="initials" type="text" component={renderField} label="Voorletter(s)"/>
                </div>
                <div className="col">
                    <Field name="infix" type="text" component={renderField} label="Tussenvoegsel"/>
                </div>
                <div className="col">
                    <Field name="lastname" type="text" component={renderField} label="Achternaam"/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <Field name="street" type="text" component={renderField} label="Straatnaam"/>
                </div>
                <div className="col">
                    <Field name="number" type="text" component={renderField} label="Huisnummer"/>
                </div>
                <div className="col">
                    <Field name="appendix" type="text" component={renderField} label="Toevoeging"/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <Field name="zipcode" type="text" component={renderField} label="Postcode"/>
                </div>
                <div className="col">
                    <Field name="city" type="text" component={renderField} label="Plaats"/>
                </div>
            </div>

            <h4>Contact</h4>

            <div className="form-group">
                <Field name="company" type="text" component={renderField} label="Bedrijfsnaam"/>
            </div>
            <div className="form-group">
                <Field name="contact" type="text" component={renderField} label="Contactpersoon"/>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <Field name="phone" type="text" component={renderField} label="Telefoonnummer"/>
                </div>
                <div className="col">
                    <Field name="email" type="text" component={renderField} label="E-mail"/>
                    <Field name="email" component={renderError}/>
                </div>
            </div>
            <hr/>

            <div className="form__group">
                <div className="form__input-wrapper">
                    <div className="form__checkbox-wrapper w-100">
                        <Field name="agreedInstallation" id="agreedInstallation" component="input" type="checkbox" value="yes"/>
                        <div className="form__checkbox" />
                        <label htmlFor="agreedInstallation" className="form__label">Ik ga akkoord met de <a
                            href="/public/pdf/omnia-connect-installatievoorwaarden-202401.pdf"
                            target="_blank" className="form__label--colored">installatie voorwaarden</a></label>
                    </div>
                    <Field name="agreedInstallation" component={renderError}/>
                </div>
            </div>
            <hr />

            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="previousStep" onClick={previousPage}>
                        &lt; Vorige stap
                    </div>
                </div>
                <div className="col-md-4">

                </div>
                <div className="col-md-4">
                    <button type="submit" className="btn btn-sm btn-block">Volgende stap</button>
                </div>
            </div>
        </form>
    );
};

InstallationContactInformation = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(InstallationContactInformation);

const selector = formValueSelector('wizard');
InstallationContactInformation = connect(
    state => ({
        hasInvoiceAddress: selector(state, 'differentInvoiceAddress'),
        discount: selector(state, 'discount'),
        formData: getFormValues('wizard')(state)
    }),
)(InstallationContactInformation);

export default InstallationContactInformation;
