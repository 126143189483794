export const TOOL_UPDATE_CUSTOMER = 'TOOL_UPDATE_CUSTOMER';
export const TOOL_UPDATE_CUSTOMERS = 'TOOL_UPDATE_CUSTOMERS';
export const TOOL_UPDATE_ORDER = 'TOOL_UPDATE_ORDER';
export const TOOL_UPDATE_LOGS = 'TOOL_UPDATE_LOGS';
export const TOOL_UPDATE_STATUS = 'TOOL_UPDATE_STATUS';
export const TOOL_UPDATE_PRODUCTS = 'TOOL_UPDATE_PRODUCTS';
export const TOOL_UPDATE_INTERNET_PRODUCTS = 'TOOL_UPDATE_INTERNET_PRODUCTS';
export const TOOL_UPDATE_NEW_PHONE_NUMBER = 'TOOL_UPDATE_NEW_PHONE_NUMBER';
export const TOOL_UPDATE_MIGRATED_PHONE_NUMBERS = 'TOOL_UPDATE_MIGRATED_PHONE_NUMBERS';
export const TOOL_UPDATE_NEW_MOBILE_NUMBERS = 'TOOL_UPDATE_NEW_MOBILE_NUMBERS';
export const TOOL_UPDATE_MIGRATED_MOBILE_NUMBERS = 'TOOL_UPDATE_MIGRATED_MOBILE_NUMBERS';
export const TOOL_UPDATE_ORDER_ID = 'TOOL_UPDATE_ORDER_ID';
export const TOOL_UPDATE_INTERNET_PRODUCT = 'TOOL_UPDATE_INTERNET_PRODUCT';
export const TOOL_UPDATE_ORDERED_INTERNET_PRODUCT = 'TOOL_UPDATE_ORDERED_INTERNET_PRODUCT';
export const TOOL_UPDATE_PRODUCT_EMAIL_SENT = 'TOOL_UPDATE_PRODUCT_EMAIL_SENT';
export const TOOL_UPDATE_ORDER_PACKAGE = 'TOOL_UPDATE_ORDER_PACKAGE';
export const TOOL_UPDATE_PRODUCTS_TO_NOTIFY = 'TOOL_UPDATE_PRODUCTS_TO_NOTIFY';
export const TOOL_UPDATE_ORDERING_INTERNET_PRODUCT = 'TOOL_UPDATE_ORDERING_INTERNET_PRODUCT';
export const TOOL_UPDATE_ORDERING_NEW_PHONE_PRODUCT = 'TOOL_UPDATE_ORDERING_NEW_PHONE_PRODUCT';
export const TOOL_UPDATE_ORDERING_PHONE_PRODUCT_MIGRATION = 'TOOL_UPDATE_ORDERING_PHONE_PRODUCT_MIGRATION';
export const TOOL_UPDATE_ORDERING_NEW_MOBILE_PRODUCT = 'TOOL_UPDATE_ORDERING_NEW_MOBILE_PRODUCT';
export const TOOL_UPDATE_ORDERING_MOBILE_PRODUCT_MIGRATION = 'TOOL_UPDATE_ORDERING_MOBILE_PRODUCT_MIGRATION';



