import React from 'react';
import {getFormValues, reduxForm} from "redux-form";
import validate from "../validate";
import connect from "react-redux/lib/connect/connect";
import {calculate} from "../helpers";
import {renderPrice} from "../../components/blocks/renderPrice";

class InstallationPriceList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            oneOffCosts: [],
            totalOneOffCosts: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formData !== this.props.formData) {
            this.update();
        }
    }

    update() {
        calculate(this.props.formData)
            .then((response) => this.setState({
                monthlyCosts: response.monthlyCosts,
                oneOffCosts: response.oneOffCosts,
                totalMonthlyCosts: response.totalMonthlyCosts,
                totalOneOffCosts: response.totalOneOffCosts
            }));
    }

    render() {
        const {totalOneOffCosts, oneOffCosts} = this.state;

        return <div>
            <hr/>
            <ul className={"priceList"}>
                {oneOffCosts.map((option, index) => <li key={index}>
                    <div className={"priceListDescription"}>{option.description}</div>
                    <div className={"priceListPrice"}>&euro; {renderPrice(option.price, ',00')}</div>
                </li>)}
            </ul>
            <hr/>
            <h6 className="text-center">Totaal prijs:</h6>
            <p className="totalPrice text-center mt-3"><span>&euro; {renderPrice(totalOneOffCosts, ',00')}</span><span>eenmalig</span></p>
        </div>
    }
}


InstallationPriceList = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(InstallationPriceList);

InstallationPriceList = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),
)(InstallationPriceList);

export default InstallationPriceList;
