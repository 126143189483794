import SignaturePad from 'signature_pad';

const canvas = document.getElementById('signature-pad');
if (canvas) {
    const signaturePad = new SignaturePad(canvas);
    function resizeCanvas() {
        let data = signaturePad.toData()
        let ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        signaturePad.fromData(data)
    }

    window.onresize = resizeCanvas;
    resizeCanvas();

    document.getElementById('clear').addEventListener('click', function () {
        signaturePad.clear();
    });

    document.getElementById('order-sign-form').addEventListener('submit', function (e) {
        if (signaturePad.isEmpty()) {
            e.preventDefault();
            alert("Een handtekening is verplicht.");

            return false;
        }

        document.getElementById('signature-data').value = signaturePad.toDataURL('image/png');

        return true;
    });
}
