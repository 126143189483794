import React from "react";
import download from 'downloadjs'
import {STEP_ALARM, STEP_CONFIRMATION, STEP_INFORMATION, STEP_OPTIONS, STEP_TELEPHONE} from './const/steps';
import toastr from 'toastr';

export function fetchAvailableProducts() {
    return fetch('/api/products')
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function fetchAvailableProductsForPackage(packageId) {
    return fetch(`/api/products-by-package/${packageId}`)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function generatePdfRequest(values) {
    fetch('/download-pdf', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
    }).then((response) => response.blob())
        .then((blob) => download(blob, "offerte.pdf", "application/pdf"));
}

export function generateInstallationPdfRequest(installationId, values) {
    fetch(`/admin/installations/${installationId}/pdf`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
    }).then((response) => response.blob())
        .then((blob) => download(blob, "werkbon.pdf", "application/pdf"));
}

export function calculate(values) {
    return fetch('/api/calculate', {
        method: 'POST',
        body: JSON.stringify(values)
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export async function uploadOrderImage(file) {
    return await uploadFile('/installations/image/upload', file);
}

export async function uploadIntakeImage(intakeId, file) {
    return await uploadFile(`/api/security-intake/${intakeId}/photo`, file);
}

export async function uploadAdditionalIntakeInfoImage(intakeId, file) {
    return await uploadFile(`/api/security-intake/${intakeId}/additional`, file);
}

export function fetchInstallationData(installationId) {
    return fetch('/api/installations/' + installationId)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function createStep(processId) {
    return fetch(`/api/order-process/${processId}/create-step`, {
        method: 'post',
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function fetchStep(stepId) {
    return fetch(`/api/order-process-step/${stepId}/details`)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function createEmptyStep() {
    return fetch(`/api/order-process-step/create`, {
        method: 'post',
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function updateStep(stepId, data) {
    return fetch(`/api/order-process-step/${stepId}/details`, {
        method: 'post',
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function fetchStepItems(stepId) {
    return fetch(`/api/order-process-step/${stepId}/items`)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function fetchAvailableStepItems() {
    return fetch(`/api/order-process-step/available-items`)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function updateStepItems(stepId, list) {
    return fetch(`/api/order-process-step/${stepId}/items/sort`, {
        method: 'post',
        body: JSON.stringify({items: list}),
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function addItemToList(stepId, itemId) {
    return fetch(`/api/order-process-step/${stepId}/items`, {
        method: 'post',
        body: JSON.stringify({item: itemId}),
    })
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
}

export function productsByType(products, type) {
    return products.filter(product => product.product_group === type);
}

export async function uploadProductImage(file) {
    return await uploadFile('/admin/products/image/upload', file, 'image');
}

export async function uploadFile(uri, file, fieldName = 'file') {
    if (file instanceof File) {
        const body = new FormData();
        body.append(fieldName, file);

        const request = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: body,
        };

        const response = await fetch(uri, request);

        if (response.status === 204) {
            return null;
        }

        if (response.ok) {
            return await response.json();
        } else {
            return false;
        }
    }
}

export function getStepsFromProducts(packageProducts) {
    const products = {
        servicePackage: productsByType(packageProducts, 'servicePackage'),
        tv: productsByType(packageProducts, 'tv'),
        routing: productsByType(packageProducts, 'routing'),
        pin: productsByType(packageProducts, 'pin'),
        telephoneOption: productsByType(packageProducts, 'telephoneOption'),
        telephone: productsByType(packageProducts, 'telephone'),
        alarm: productsByType(packageProducts, 'alarm'),
        camera: productsByType(packageProducts, 'camera'),
    };

    const steps = [];

    if (products.servicePackage.length > 0 || products.tv.length > 0 || products.routing.length > 0 || products.pin.length > 0) {
        steps.push(STEP_OPTIONS);
    }

    if (products.telephoneOption.length > 0 || products.telephone.length > 0) {
        steps.push(STEP_TELEPHONE);
    }

    if (products.alarm.length > 0 || products.camera.length > 0) {
        steps.push(STEP_ALARM);
    }

    steps.push(STEP_INFORMATION);
    steps.push(STEP_CONFIRMATION);

    return steps;
}

export function getPreviousPage(step, steps) {
    const index = steps.indexOf(step);

    // Check if it's the first step
    if (index === 0) {
        return step;
    }

    return steps[index - 1];
}


export function getNextPage(step, steps) {
    const index = steps.indexOf(step);

    // Check if it's the last step
    if (index === steps.length - 1) {
        return step;
    }

    return steps[index + 1];
}

export function getLastStep(steps) {
    return steps[steps.length - 1];
}

export async function sendOrderConfirmation(values) {
    toastr.options.progressBar = true;
    toastr.success(`Aanvraag in behandeling. Een moment geduld aub.`)

    const response = await fetch('/send-order-confirmation', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values)
    });

    if (response.status === 204) {
        window.location = '/orders/quotation-sent';
    } else {
        toastr.error('Fout bij het verzenden van de order. Controleer of alle gegevens zijn ingevuld.')
    }
}
