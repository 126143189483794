export const STEP_OPTIONS = 'options';
export const STEP_TELEPHONE = 'telephone';
export const STEP_MOBILE = 'mobileTelephone';
export const STEP_MISC = 'misc';
export const STEP_ALARM = 'alarm';
export const STEP_INFORMATION = 'information';
export const STEP_CONFIRMATION = 'confirmation';
export const STEP_CAMERA = 'camera';
export const STEP_INSTALLATION = 'installation';

